@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
  --border-width: 2px;
  --radius-sm: 12px;
  
  /* Color Palette */
  --color-bg: #000000;          /* Pure Black - Main Background */
  --color-card-bg: #252121;     /* Dark Gray - Card Background */
  --color-text: #ffffff;        /* White - Primary Text */
  --color-accent: #e7a143;      /* Orange - Accent/Highlight */
  --color-accent-2: #c92f2e;    /* Red - Secondary Accent */
  --color-dark-accent: #490d08; /* Dark Red - Dark Accent */
  --color-border: #252121;      /* Dark Gray - Borders */
  --color-text-light: #ffffffaa; /* Transparent White - Secondary Text */
  --color-accent-rgb: 138, 43, 226;  /* RGB values for your accent color */
  --callprep-blue-rgb: 0, 122, 255;  /* Bright blue */
}

.panel, .card, .project-card, .messages-content {
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius-sm);
  background: var(--color-card-bg);
  position: relative;
}

.panel-left, .panel-center, .panel-right {
  background: var(--color-bg);
}

.messages-content {
  background: var(--color-bg);
  backdrop-filter: blur(16px);
}

.card-glass {
  background: rgba(26, 26, 26, 0.9);
  backdrop-filter: blur(12px);
}

.panel::before, .card::before, .project-card::before, .messages-content::before,
.panel::after, .card::after, .project-card::after, .messages-content::after {
  display: none;
}

.dashboard-container {
  min-height: 100vh;
  padding: calc(2rem + var(--border-width));
  background: var(--color-bg);
  display: flex;
  gap: 1.5rem;
  box-sizing: border-box;
  animation: fadeIn 1s ease-out;
}

.content-main {
  background: var(--color-bg);
}

.chat-messages {
  background: var(--color-bg);
}

.chat-input {
  background: var(--color-card-bg);
}

.panel-left {
  width: 60px;
  position: sticky;
  height: calc(100vh - 4rem);
  overflow: hidden;
  position: relative;
  border-radius: var(--radius-sm);
  background-clip: padding-box;
  z-index: 1;
  animation: fadeInLeft 0.8s ease-out;
}

.logo-container {
  margin-bottom: 2rem;
  text-align: center;
  cursor: pointer;
  padding: 1rem 0;
  animation: scaleIn 0.8s ease-out;
}

.logo-container i {
  font-size: 1.5rem;
  color: var(--color-text-light);
  transition: var(--transition-smooth);
}

.logo-container:hover i {
  color: var(--color-text);
}

.nav-label {
  display: none;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.nav-menu li {
  opacity: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: fadeInLeft 0.5s ease-out forwards;
}

.nav-menu li:nth-child(1) { animation-delay: 0.2s; }
.nav-menu li:nth-child(2) { animation-delay: 0.3s; }
.nav-menu li:nth-child(3) { animation-delay: 0.4s; }
.nav-menu li:nth-child(4) { animation-delay: 0.5s; }

.nav-menu li i,
.logo-container i {
  font-size: 1.5rem;
  color: var(--color-text-light);
  transition: var(--transition-smooth);
}

.nav-menu li:hover i,
.logo-container:hover i {
  color: var(--color-accent);
}

.panel-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--radius-sm);
  background-clip: padding-box;
  margin: calc(-1 * var(--border-width));
  padding: var(--border-width);
  height: calc(100vh - 4rem);
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.panel-center::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.panel-center::-webkit-scrollbar-track {
  background: transparent;
}

.panel-center::-webkit-scrollbar-thumb {
  background-color: var(--color-accent);
  border-radius: 4px;
}

.content-main {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--color-bg);
  border-radius: var(--radius-sm);
}

.split-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1.5rem;
  height: fit-content;
  margin-bottom: 1.5rem;
}

.box {
  height: 100%;
}

.box-small {
  min-width: 300px;
}

.box-large {
  flex: 1;
}

.featured-projects {
  padding: 2rem;
  height: 100%;
}

.featured-projects h2 {
  font-family: var(--font-heading);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--color-text);
  opacity: 0.9;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.project-card {
  position: relative;
  border-radius: var(--radius-sm);
  background-clip: padding-box;
  z-index: 1;
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.project-card:nth-child(1) { animation-delay: 0.3s; }
.project-card:nth-child(2) { animation-delay: 0.4s; }
.project-card:nth-child(3) { animation-delay: 0.5s; }
.project-card:nth-child(4) { animation-delay: 0.6s; }

.project-image {
  width: 100%;
  height: 160px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: var(--radius-sm);
  margin-bottom: 1rem;
}

.project-card h3 {
  font-family: var(--font-heading);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--color-text);
}

.project-card p {
  color: var(--color-text-light);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.project-tags span {
  background: rgba(255, 255, 255, 0.05);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius-sm);
  font-size: 0.8rem;
  color: var(--color-text-light);
}

.project-card:hover {
  border-color: var(--color-accent);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.dashboard-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.panel-right {
  width: 320px;
  position: relative;
  border-radius: var(--radius-sm);
  background-clip: padding-box;
  z-index: 1;
  animation: fadeInRight 0.8s ease-out;
}

.chat-header {
  padding: 1.5rem;
  border-bottom: var(--border-width) solid rgba(255, 255, 255, 0.05);
}

.chat-header h2 {
  font-family: var(--font-heading);
  font-size: 1.5rem;
  margin: 0;
  color: var(--color-text);
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.chat-input {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  border-top: var(--border-width) solid rgba(255, 255, 255, 0.05);
}

.chat-input input {
  flex: 1;
  background: rgba(255, 255, 255, 0.03);
  border: var(--border-width) solid rgba(255, 255, 255, 0.08);
  padding: 0.75rem 1rem;
  border-radius: var(--radius-sm);
  color: var(--color-text);
  font-family: var(--font-body);
}

.chat-input input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.2);
}

.chat-input button {
  background: transparent;
  border: var(--border-width) solid rgba(255, 255, 255, 0.1);
  color: var(--color-text-light);
  padding: 0.75rem;
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: var(--transition-smooth);
}

.chat-input button:hover {
  background: rgba(255, 255, 255, 0.05);
  color: var(--color-accent);
  border-color: var(--color-accent);
}

.card {
  position: relative;
  border-radius: var(--radius-sm);
  background-clip: padding-box;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.name-header {
  padding: 2rem;
  border-bottom: var(--border-width) solid var(--color-border);
  animation: fadeInUp 0.8s ease-out;
}

.name-header h1 {
  font-family: var(--font-heading);
  font-size: 2.8rem;
  color: var(--color-text);
  margin: 0;
  font-weight: 500;
}

.profile-section {
  padding: 1.5rem;
  height: 100%;
}

.bio-text {
  color: var(--color-text);
  line-height: 1.6;
  font-size: 1.35rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin: 0;
  text-align: justify;
  text-justify: inter-word;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: fadeInUp 0.8s ease-out 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
}

.photo-container {
  width: 100%;
  aspect-ratio: 1;
  animation: scaleIn 1s ease-out;
}

.flip-card {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: var(--radius-sm);
  overflow: hidden;
}

.flip-card-front {
  background: rgba(255, 255, 255, 0.03);
}

.flip-card-back {
  background: var(--color-card-bg);
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-width) solid rgba(255, 255, 255, 0.1);
}

.back-content {
  padding: 2rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.back-content p {
  color: var(--color-text-light);
  font-size: 1rem;
  line-height: 1.7;
  margin: 0;
  font-family: var(--font-body);
  max-width: 85%;
  font-weight: 300;
  letter-spacing: 0.02em;
}

.quote-attribution {
  margin-top: 1.2rem;
  color: var(--color-text-light);
  font-style: italic;
  font-size: 0.9rem;
  opacity: 0.7;
}

.quote {
  color: var(--color-text-light);
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 1rem;
  opacity: 0.8;
  line-height: 1.4;
}

.role-tag {
  background: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem;
  border-radius: var(--radius-sm);
  color: var(--color-text-light);
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  animation: scaleIn 0.8s ease-out 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.skills-list span {
  background: rgba(255, 255, 255, 0.03);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius-sm);
  color: var(--color-text-light);
  font-size: 0.85rem;
  border: var(--border-width) solid rgba(255, 255, 255, 0.1);
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.skills-list span:nth-child(3n+1) { animation-delay: 0.2s; }
.skills-list span:nth-child(3n+2) { animation-delay: 0.3s; }
.skills-list span:nth-child(3n+3) { animation-delay: 0.4s; }

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
}

.flip-card-front iframe.profile-image {
  border: none;
  border-radius: 10px;
}

.social-links {
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  padding: 0.5rem 0;
  animation: fadeInUp 0.8s ease-out 0.4s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.social-links a {
  color: var(--color-text-light);
  font-size: 1.25rem;
  transition: var(--transition-smooth);
  text-decoration: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-sm);
}

.social-links a:hover {
  color: var(--color-text);
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
}

.header-section {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: var(--border-width) solid rgba(255, 255, 255, 0.1);
  animation: fadeInUp 0.8s ease-out;
}

.header-section h1 {
  font-family: var(--font-heading);
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-text);
  margin: 0;
  letter-spacing: -0.02em;
  line-height: 1.2;
  animation: fadeInLeft 0.8s ease-out 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.header-section .title {
  font-family: var(--font-body);
  font-size: 1.1rem;
  color: var(--color-text-light);
  margin: 0.5rem 0 0 0;
  font-weight: 300;
  letter-spacing: 0.05em;
  animation: fadeInLeft 0.8s ease-out 0.4s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.highlight-primary {
  color: var(--color-accent);
  font-weight: 400;
  transition: color 0.3s ease;
}

.highlight-primary:hover {
  color: var(--color-accent-2);
}

.highlight-secondary {
  color: var(--color-accent-2);
  font-weight: 400;
  transition: color 0.3s ease;
}

.highlight-secondary:hover {
  color: var(--color-accent);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

.messages-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(12px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.messages-content {
  width: 95%;
  max-width: 800px;
  height: 85vh;
  position: relative;
  border-radius: var(--radius-sm);
  background-clip: padding-box;
  z-index: 1;
}

.messages-header {
  padding: 1.5rem;
  border-bottom: var(--border-width) solid rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-content i {
  font-size: 1.5rem;
  color: var(--color-text-light);
  animation: pulse 2s infinite;
}

.messages-header h2 {
  font-family: var(--font-heading);
  font-size: 1.75rem;
  margin: 0;
  color: var(--color-text);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.messages-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.messages-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1.5rem;
}

.messages-input {
  display: flex;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
}

.messages-input input {
  flex: 1;
  background: rgba(255, 255, 255, 0.03);
  border: var(--border-width) solid rgba(255, 255, 255, 0.08);
  padding: 0.75rem 1rem;
  border-radius: var(--radius-sm);
  color: var(--color-text);
  font-family: var(--font-body);
}

.messages-input input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.2);
}

.messages-input button {
  background: transparent;
  border: var(--border-width) solid rgba(255, 255, 255, 0.1);
  color: var(--color-text-light);
  padding: 0.75rem;
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: var(--transition-smooth);
}

.messages-input button:hover {
  background: rgba(255, 255, 255, 0.05);
  color: var(--color-accent);
  border-color: var(--color-accent);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.button-primary {
  background: var(--color-accent);
  color: var(--color-bg);
}

.button-primary:hover {
  background: var(--color-accent-2);
}

.button-secondary {
  border: 2px solid var(--color-accent);
  color: var(--color-accent);
}

.button-secondary:hover {
  background: var(--color-accent);
  color: var(--color-bg);
}

.status-indicator.active {
  background: var(--color-accent-2);
}

*, *::before, *::after {
  box-sizing: border-box;
}

.skills-section {
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 1.5rem;
}

.skills-header {
  background: var(--color-card-bg);
  border-radius: var(--radius-sm);
  padding: 1.5rem 2rem;
  height: 18rem;
  width: 160px;
}

.skills-header ul {
  list-style:none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.skills-header li {
  margin-top: 1rem;
  color: var(--color-text);
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 350;
  font-family: 'Space Grotesk', sans-serif;
  letter-spacing: 0.03em;
  opacity: 0.9;
  line-height: 1.5;
}

/* Calculate total height:
   Padding top/bottom: 1.5rem * 2 = 3rem
   3 items * (font-size 1.6rem * line-height 1.2) = 5.76rem
   2 margins between items * 1rem = 2rem
   Total: 10.76rem ≈ 172px
*/

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem 1rem;
  height: auto;
  padding: 0 0;
  align-items: start;
}

.skill-flip-card {
  width: 100%;
  aspect-ratio: 1;
  perspective: 1000px;
  cursor: pointer;
  min-width: 120px;
}

.skill-flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.skill-flip-card:hover .skill-flip-card-inner {
  transform: rotateY(180deg);
}

.skill-flip-card-front, .skill-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: var(--radius-sm);
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill-flip-card-front {
  padding: 0.5rem;
}

.skill-flip-card-back {
  transform: rotateY(180deg);
  background: rgba(231, 161, 67, 0.1);
  backdrop-filter: blur(5px);
}

.skill-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  transition: transform 0.3s ease;
}

.skill-logo svg {
  width: 3.5rem;
  height: 3.5rem;
}

.skill-flip-card:hover .skill-logo {
  transform: scale(1.1);
}

.skill-name {
  font-size: 1.1rem;
  color: var(--color-text);
  padding: 0.5rem;
  text-align: center;
  font-weight: 500;
  margin-top: 0.75rem;
}

@media (max-width: 768px) {
  .skill-name {
    font-size: 1rem;
  }
}

.info-boxes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.info-box {
  background: var(--color-card-bg);
  border-radius: var(--radius-sm);
  padding: 1.5rem;
  border: var(--border-width) solid var(--color-border);
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.info-box-icon {
  font-size: 2rem;
  color: var(--color-accent);
  margin-bottom: 1rem;
}

.info-box-title {
  font-size: 1.2rem;
  color: var(--color-text);
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.info-box-description {
  font-size: 0.9rem;
  color: var(--color-text-light);
  text-align: center;
}

.experience-panels {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.experience-panel {
  background: var(--color-card-bg);
  border-radius: var(--radius-sm);
  padding: 1.8rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.experience-panel:nth-child(1) {
  animation-delay: 0.2s;
}

.experience-panel:nth-child(2) {
  animation-delay: 0.4s;
}

.experience-panel:nth-child(3) {
  animation-delay: 0.6s;
}

.experience-description {
  flex-grow: 1;
  margin: 1.2rem 0;
  line-height: 1.7;
  font-size: 1.05rem;
}

.tech-stack {
  margin-top: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.experience-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.company-logo {
  width: 40px;
  height: 40px;
  border-radius: var(--radius-sm);
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--color-accent);
}

.experience-title {
  flex: 1;
}

.company-name {
  font-size: 1.2rem;
  color: var(--color-text);
  font-weight: 500;
  margin: 0;
}

.job-title {
  font-size: 0.9rem;
  color: var(--color-accent);
  margin: 0.2rem 0;
}

.duration {
  font-size: 0.8rem;
  color: var(--color-text-light);
}

.experience-description {
  font-size: 0.9rem;
  color: var(--color-text-light);
  line-height: 1.6;
  margin-top: 1rem;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: auto;
  padding-top: 1rem;
}

.tech-tag {
  font-size: 0.8rem;
  color: var(--color-text-light);
  background: rgba(255, 255, 255, 0.05);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius-sm);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .experience-panels {
    grid-template-columns: 1fr;
  }
}

.experience-panel.featured {
  background: linear-gradient(145deg, 
    rgba(0, 122, 255, 0.15), 
    rgba(0, 122, 255, 0.05)
  );
  border: 1px solid rgba(0, 122, 255, 0.2);
  box-shadow: 0 0 20px rgba(0, 122, 255, 0.1);
}

.experience-panel.featured::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3rem 3rem 0;
  border-color: transparent rgba(0, 122, 255, 0.2) transparent transparent;
}

.experience-panel.featured .company-logo {
  color: rgb(0, 122, 255);
}

.experience-panel:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.education-panels {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1rem 0;
  width: 100%;
}

.education-panel {
  background: var(--color-card-bg);
  border-radius: var(--radius-sm);
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.education-panel:nth-child(1) {
  animation-delay: 0.3s;
}

.education-panel:nth-child(2) {
  animation-delay: 0.5s;
}

.education-panel:nth-child(3) {
  animation-delay: 0.7s;
}

@media (max-width: 1200px) {
  .education-panels {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .education-panels {
    grid-template-columns: 1fr;
  }
}

.education-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.2rem;
}

.education-info {
  flex-grow: 1;
}

.institution-name {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(to right, var(--color-text) 0%, var(--color-text-light) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.degree-name {
  font-size: 1.1rem;
  color: var(--color-text-light);
  margin-bottom: 0.3rem;
}

.education-duration {
  font-size: 0.9rem;
  color: var(--color-text-muted);
}

.education-location {
  text-align: right;
  color: var(--color-text-muted);
  font-size: 0.9rem;
}

.education-details {
  margin: 1rem 0;
  color: var(--color-text-light);
  line-height: 1.6;
}

.education-highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-top: 1rem;
}

.highlight-tag {
  background: rgba(231, 161, 67, 0.1);
  color: var(--color-accent);
  padding: 0.4rem 0.8rem;
  border-radius: var(--radius-sm);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .education-header-section .section-title {
    font-size: 2rem;
  }

  .education-header-section .background-text {
    font-size: 6rem;
  }
}

.experience-header-section {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
}

.experience-title-container {
  position: relative;
  text-align: center;
}

.experience-main-title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--color-text);
  position: relative;
  z-index: 2;
  margin: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: linear-gradient(45deg, var(--color-accent), var(--color-text));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.experience-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8rem;
  font-weight: 800;
  opacity: 0.05;
  white-space: nowrap;
  letter-spacing: 0.3em;
  color: var(--color-text);
  text-transform: uppercase;
  pointer-events: none;
}

.name-header-section {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.name-title-container {
  position: relative;
  text-align: center;
}

.name-main-title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--color-text);
  position: relative;
  z-index: 2;
  margin: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: linear-gradient(45deg, var(--color-accent), var(--color-text));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.name-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8rem;
  font-weight: 800;
  opacity: 0.05;
  white-space: nowrap;
  letter-spacing: 0.3em;
  color: var(--color-text);
  text-transform: uppercase;
  pointer-events: none;
}

.education-header-section {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  margin-top: 2rem;
}

.education-header-section .section-header {
  position: relative;
  z-index: 1;
}

.education-header-section .section-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(to right, var(--color-text) 0%, var(--color-text-light) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.education-header-section .background-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 8rem;
  font-weight: 800;
  opacity: 0.03;
  white-space: nowrap;
  color: var(--color-text);
  z-index: -1;
}

/* Loading Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Enhanced Loading Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideInBottom {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animations to all items */
.dashboard-container {
  animation: fadeIn 1s ease-out;
}

.panel-left {
  animation: fadeInLeft 0.8s ease-out;
}

.panel-right {
  animation: fadeInRight 0.8s ease-out;
}

.nav-menu li {
  opacity: 0;
  animation: fadeInLeft 0.5s ease-out forwards;
}

.nav-menu li:nth-child(1) { animation-delay: 0.2s; }
.nav-menu li:nth-child(2) { animation-delay: 0.3s; }
.nav-menu li:nth-child(3) { animation-delay: 0.4s; }
.nav-menu li:nth-child(4) { animation-delay: 0.5s; }

.logo-container {
  animation: scaleIn 0.8s ease-out;
}

.header-section {
  animation: fadeInUp 0.8s ease-out;
}

.header-section h1 {
  animation: fadeInLeft 0.8s ease-out 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.header-section .title {
  animation: fadeInLeft 0.8s ease-out 0.4s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.project-card {
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.project-card:nth-child(1) { animation-delay: 0.3s; }
.project-card:nth-child(2) { animation-delay: 0.4s; }
.project-card:nth-child(3) { animation-delay: 0.5s; }
.project-card:nth-child(4) { animation-delay: 0.6s; }

.skills-list span {
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.skills-list span:nth-child(3n+1) { animation-delay: 0.2s; }
.skills-list span:nth-child(3n+2) { animation-delay: 0.3s; }
.skills-list span:nth-child(3n+3) { animation-delay: 0.4s; }

.role-tag {
  animation: scaleIn 0.8s ease-out 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

/* Enhanced hover effects */
.project-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.skills-list span {
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.skills-list span:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

.nav-menu li {
  transition: transform 0.2s ease, color 0.2s ease;
}

.nav-menu li:hover {
  transform: translateY(-2px);
}

.social-links a {
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.social-links a:hover {
  transform: translateY(-3px);
  background: rgba(255, 255, 255, 0.1);
}

/* Smooth transitions for all interactive elements */
.experience-panel, .education-panel, .project-card, .nav-menu li, .social-links a {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Add hover lift effect to all cards */
.experience-panel:hover, .education-panel:hover, .project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Add subtle hover effect to all interactive elements */
.nav-menu li:hover, .social-links a:hover, .skills-list span:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    padding: 1rem;
  }

  .panel-left {
    width: 100%;
    height: 60px;
    transform: translateY(0);
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--color-bg);
    border-top: 1px solid var(--color-border);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 1000;
    animation: none;
  }

  .logo-container {
    display: none;
  }

  .nav-menu {
    width: 100%;
    padding: 0;
  }

  .nav-menu ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0 20px;
    gap: 0;
  }

  .nav-menu li {
    width: auto;
    height: auto;
    margin: 0;
    padding: 12px;
    opacity: 1;
    animation: none;
  }

  .nav-menu li i {
    font-size: 1.4rem;
    margin: 0;
  }

  .panel-center {
    padding-bottom: 70px;
    margin-left: 0;
    width: 100%;
  }

  /* Fix text overflow in experience and education sections */
  .experience-background-text,
  .education-background-text {
    font-size: 2.5rem;
    opacity: 0.1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100vw;
  }

  .experience-main-title,
  .education-main-title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .experience-panel,
  .education-panel {
    padding: 1.25rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
  }

  .company-name,
  .institution-name {
    font-size: 1.1rem;
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }

  .job-title,
  .degree-name {
    font-size: 0.9rem;
    line-height: 1.2;
    margin-bottom: 0.25rem;
  }

  .duration,
  .education-duration {
    font-size: 0.8rem;
  }

  .experience-description,
  .education-details {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0.75rem 0;
  }

  .tech-stack,
  .education-highlights {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .tech-tag,
  .highlight-tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    white-space: nowrap;
  }

  /* Improve mobile menu toggle */
  .mobile-menu-toggle {
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.75rem;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 8px;
  }

  /* Fix content spacing */
  .content-main {
    padding: 1rem;
  }

  .split-layout {
    gap: 1.5rem;
  }

  /* Fix name title overflow */
  .name-background-text {
    font-size: 2.5rem;
    opacity: 0.1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100vw;
  }

  .name-main-title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  /* Improve experience and education panels layout */
  .experience-panels,
  .education-panels {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0.5rem;
  }

  .experience-header,
  .education-header {
    flex-wrap: wrap;
  }

  .company-logo,
  .education-logo {
    margin-right: 0.75rem;
  }

  /* Optimize for different mobile heights */
  @media (max-height: 700px) {
    .panel-center {
      padding-top: 0.5rem;
    }

    .name-main-title {
      font-size: 1.5rem;
    }

    .name-background-text {
      font-size: 2rem;
    }

    .experience-main-title,
    .education-main-title {
      font-size: 1.5rem;
    }
  }
}

/* Improve tablet navigation */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .panel-left {
    width: 70px;
  }

  .nav-menu li {
    padding: 0.75rem;
  }

  .nav-menu li i {
    font-size: 1.25rem;
  }

  .experience-background-text,
  .education-background-text {
    font-size: 3rem;
  }
}

/* Tablet Responsiveness */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .dashboard-container {
    padding: 1rem;
  }

  .split-layout {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .box-small {
    max-width: 400px;
    margin: 0 auto;
  }

  .skills-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .experience-panels, .education-panels {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Landscape Mode */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .panel-left {
    padding: 0.25rem 0;
  }

  .nav-menu li {
    padding: 0.25rem;
  }

  .panel-center {
    margin-bottom: 40px;
  }

  .skills-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Ensure animations work smoothly on mobile */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  display: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1001;
  background: transparent;
  border: none;
  color: var(--color-text);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.mobile-menu-toggle:hover {
  transform: scale(1.1);
}

/* Touch-friendly styles */
@media (hover: none) {
  .skill-flip-card:hover .skill-flip-card-inner,
  .flip-card:hover .flip-card-inner {
    transform: none;
  }

  .skill-flip-card.touched .skill-flip-card-inner,
  .flip-card.touched .flip-card-inner {
    transform: rotateY(180deg);
  }

  .nav-menu li,
  .social-links a {
    padding: 12px;  /* Larger touch targets */
  }
}

/* Improved mobile styles */
@media screen and (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
  }

  .dashboard-container {
    transition: transform 0.3s ease;
  }

  .dashboard-container.menu-open {
    transform: translateX(250px);
  }

  .panel-left {
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background: var(--color-bg);
    z-index: 1000;
  }

  .panel-left.open {
    transform: translateX(0);
  }

  .messages-overlay {
    padding: 0.5rem;
  }

  .messages-content {
    width: 100%;
    max-width: none;
    height: 90vh;
    margin: 5vh 0;
  }

  .messages-input {
    padding: 0.75rem;
  }

  .messages-input input {
    height: 44px;  /* Larger touch target */
  }

  .messages-input button {
    width: 44px;
    height: 44px;
  }

  .close-button {
    padding: 12px;  /* Larger touch target */
  }

  /* Improved scrolling on mobile */
  .panel-center {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overscroll-behavior-y: contain;
  }

  /* Better touch feedback */
  .nav-menu li:active,
  .social-links a:active,
  .tech-tag:active,
  .highlight-tag:active {
    opacity: 0.7;
    transform: scale(0.95);
  }

  /* Prevent text selection during touch interactions */
  .nav-menu,
  .social-links,
  .tech-stack,
  .education-highlights {
    user-select: none;
    -webkit-user-select: none;
  }

  /* Optimize animations for mobile */
  @media (prefers-reduced-motion: no-preference) {
    .fadeInUp,
    .fadeInLeft,
    .fadeInRight,
    .scaleIn {
      animation-duration: 0.5s;
    }
  }
}

/* Tablet Landscape Mode */
@media screen and (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
  .panel-left {
    width: 60px;
  }

  .logo-container {
    padding: 0.75rem;
  }

  .nav-menu li {
    padding: 0.75rem;
  }
}

/* Prevent body scroll when overlay is open */
body.overlay-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* Navigation Labels */
.nav-label {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
  .nav-label {
    display: none;
  }

  .panel-left {
    width: 100%;
    height: 60px;
    transform: translateY(0);
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--color-bg);
    border-top: 1px solid var(--color-border);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 1000;
  }

  .nav-menu {
    width: 100%;
  }

  .nav-menu ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0 20px;
  }

  .nav-menu li {
    width: auto;
    height: auto;
    margin: 0;
    padding: 12px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-menu li i {
    font-size: 1.4rem;
    margin: 0;
  }

  /* Show labels in drawer mode */
  .dashboard-container.menu-open .nav-label {
    display: inline-block;
  }
}

/* Mobile Navigation Drawer */
@media screen and (max-width: 768px) {
  .dashboard-container.menu-open .panel-left {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(0);
    padding-top: 60px;
    border-top: none;
    border-right: 1px solid var(--color-border);
  }

  .dashboard-container.menu-open .nav-menu ul {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 0;
  }

  .dashboard-container.menu-open .nav-menu li {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem 0;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }

  .dashboard-container.menu-open .nav-menu li:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .dashboard-container.menu-open .nav-menu li i {
    width: 24px;
    text-align: center;
    margin-right: 1rem;
  }

  .dashboard-container.menu-open .nav-label {
    display: inline-block;
    font-size: 1rem;
  }

  /* Overlay for drawer mode */
  .dashboard-container.menu-open::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 999;
    animation: fadeIn 0.3s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Hide mobile menu toggle when drawer is open */
  .dashboard-container.menu-open .mobile-menu-toggle {
    display: none;
  }
}
